import { getSocialNetworkInfoByName } from "./form.utils";

export const getContactLabel = (contactItem, contactKey) => {
  if (contactKey === "phones") {
    return contactItem["tag"];
  }
  if (contactKey === "email") {
    return "Correo";
  }
  if (contactKey === "webpage") {
    return "Web Empresa";
  }
  if (contactKey === "pdf") {
    return "PDF";
  }
  if (contactKey === "businessPresentations") {
    return "Video";
  }
  if (contactKey === "address") {
    return "Dirección";
  }
  if (contactKey === "webpages") {
    return contactItem["tag"];
  }
  return "";
};

export const getContactStaticValue = (contactItem, contactKey) => {
  if (contactKey === "phones") {
    return `${contactItem["extension"] || ""} ${contactItem["value"]}`;
  }
  if (contactKey === "email") {
    return contactItem;
  }
  if (contactKey === "webpage") {
    return contactItem;
  }
  if (contactKey === "webpages") {
    return contactItem["value"];
  }
  if (contactKey === "pdf") {
    return contactItem["filename"] || "Presentación Empresa";
  }
  if (contactKey === "businessPresentations") {
    return contactItem["filename"] || "Presentación Empresa";
  }
  if (contactKey === "address") {
    return contactItem;
  }
  return "";
};

export const getContactValue = (contactItem, contactKey) => {
  if (contactKey === "phones") {
    return `tel:${contactItem["extension"] || ""}${contactItem["value"]}`;
  }
  if (contactKey === "webpages") {
    return contactItem["value"].includes("http://") ||
      contactItem["value"].includes("https://")
      ? contactItem["value"]
      : `https://${contactItem["value"]}`;
  }
  if (contactKey === "email") {
    return `mailto:${contactItem}`;
  }
  if (contactKey === "webpage") {
    return contactItem.includes("http://") || contactItem.includes("https://")
      ? contactItem
      : `https://${contactItem}`;
  }
  if (contactKey === "pdf") {
    const finalUrl = contactItem["externalUrl"] || contactItem["url"] || "";
    return finalUrl.includes("http://") || finalUrl.includes("https://")
      ? finalUrl
      : `https://${finalUrl}`;
  }
  if (contactKey === "businessPresentations") {
    const finalUrl = contactItem["externalUrl"] || contactItem["url"] || "";
    return finalUrl.includes("http://") || finalUrl.includes("https://")
      ? finalUrl
      : `https://${finalUrl}`;
  }
  if (contactKey === "address") {
    return `https://www.google.com/maps/search/${contactItem}`;
  }
  return "";
};

export const getSocialValue = (socialItem, socialKey) => {
  const data = getSocialNetworkInfoByName(socialKey);
  return `https://${data.baseUrl}${socialItem}`;
};
