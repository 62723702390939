import styled from "@emotion/styled";
import React, { useState, useEffect } from "react";

import Button from "../../common/atoms/Button";
import theme from "../../common/theme";

const ButtonStyled = styled(Button)`
  display: flex;
  gap: 10px;
  align-items: center;
  border-radius: 50%;
  border: 3px solid white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 8px;
  background-color: white;
  color: ${theme.colors.white};

  padding-inline: 15px;
  transform: perspective(1px) translateZ(0);
  transition-duration: 0.3s;
  transition-property: transform;
  &:hover,
  &:active,
  &:focus {
    transform: scale(0.9);
  }
`;

const ShortcutButton = ({ disabled }) => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };
    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleClick = () => {
    if (disabled) return;
    if (deferredPrompt) {
      deferredPrompt.prompt();

      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("El usuario aceptó la instalación");
        } else {
          console.log("El usuario rechazó la instalación");
        }
        setDeferredPrompt(null);
      });
    }
  };

  return (
    <ButtonStyled onClick={handleClick} size="large" disabled={disabled}>
      <i
        className="bi bi-house-add"
        style={{
          fontSize: "1.5rem",
          color: "#000",
        }}
      />
    </ButtonStyled>
  );
};

export default ShortcutButton;
