import React from "react";
import { createTheme, MuiThemeProvider } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { FlagsProvider } from "flagged";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { AuthProvider } from "./auth-management/context/AuthContext";
import AppRoutes from "./routing/AppRoutes";
import theme from "./common/theme";

const THEME = createTheme(theme);
function App() {
  const { features } = useSelector((state) => state.currentCompany);
  return (
    <FlagsProvider features={features}>
      <MuiThemeProvider theme={THEME}>
        <AuthProvider>
          <div className="App" style={{ width: "100%" }}>
            <AppRoutes />
            <ToastContainer />
          </div>
        </AuthProvider>
      </MuiThemeProvider>
    </FlagsProvider>
  );
}

export default App;
