import * as React from "react";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import QRDisplay from "./QRDisplay";
import styled from "@emotion/styled";
import CardUrlSection from "../components/CardUrlSection";

const ModalBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #fff;
  box-shadow: 24px;
  border-radius: 5px;
  padding: 16px;
  outline: none;
  text-align: center;
  display: grid;
  place-items: center;
  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

export default function ModalQR({
  title,
  subtitle,
  description,
  baseUrl,
  cardPathKey,
  logoImage,
  disabled,
  colors,
  open = false,
  handleClose,
}) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
      >
        <Fade in={open}>
          <ModalBox>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="mb-3"
            >
              Código QR
            </Typography>
            <QRDisplay
              title={title}
              subtitle={subtitle}
              description={description}
              baseUrl={baseUrl}
              cardPathKey={cardPathKey}
              logoImage={logoImage}
              disabled={disabled}
              colors={colors}
            />
            <CardUrlSection
              baseUrl={baseUrl}
              cardPathKey={cardPathKey}
              disabled={false}
            />
          </ModalBox>
        </Fade>
      </Modal>
    </div>
  );
}
